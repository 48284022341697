import _ from "lodash";

/**
 * Get courses list formatted for filters in Contatti page
 *
 * @param {array} apiCoursesResult
 * @param {boolean} isReturningAsFilter
 *
 * @returns {array}
 *
 */
export default function(apiCoursesResult, isReturningAsFilter = true) {
    let courses = [];

    // console.log('apiCoursesResult', apiCoursesResult);

    if (
        typeof apiCoursesResult === "undefined" ||
        apiCoursesResult === null ||
        (Array.isArray(apiCoursesResult) && apiCoursesResult.length === 0)
    ) {
        return courses;
    }

    /**
     * Define all static options
     */
    const unassigned = {
        code: "UNASSIGNED",
        description: "",
        id: -3,
        value: -3,
        name: "Senza corso",
        sorting: 0,
    };

    const biennial = {
        code: "ITS",
        description: "",
        id: -1,
        value: -1,
        name: "ITS",
        sorting: 1,
    };

    const triennial = {
        code: "IFTS",
        description: "",
        id: -2,
        value: -2,
        name: "IFTS",
        sorting: 2,
    };

    if (isReturningAsFilter) {
        courses.unshift({
            label: "Percorsi di studio",
            options: [unassigned, biennial, triennial],
            sorting: 1,
        });

        /**
         * Manage courses option
         */
        courses.push({
            label: "Corsi",
            options: [],
            sorting: 1,
        });
    }

    /**
     * Add courses list to options
     */
    const patternDip = /^DIP_/gm;

    const dipartmentsValuesList = {};

    // const typeDipartement = [];
    // const dipartementModa = [];
    // const dipartemenMarketing = [];
    // const dipartemenDesign = [];
    const coursesIFTS = [];

    /**
     * Get dipartments
     */
    const dipartments = apiCoursesResult.filter((course) => {
        const matchDip = String(course.type).match(patternDip);
        return Array.isArray(matchDip) && matchDip.length > 0;
    });

    if (Array.isArray(dipartments) && dipartments.length > 0) {
        const reorderedDips = _.orderBy(dipartments, ['name', 'sorting']);

        [...reorderedDips].forEach((dip) => {
            const newDipartment = { ...dip };
            const cleanedType = newDipartment.type.replace("DIP_", "");

            newDipartment.html = `&ensp; &ensp;${newDipartment.name}`;

            const existsValuesList = Object.prototype.hasOwnProperty.call(
                dipartmentsValuesList,
                cleanedType
            );

            newDipartment.value = `${newDipartment.id}${
                existsValuesList === true
                    ? `,${dipartmentsValuesList[cleanedType].toString()}`
                    : ""
            }`;

            /**
             * Get courses of current dipartment
             */
            const dipCourses = apiCoursesResult.filter((course) => {
                const matchDip = String(course.type).match(patternDip);
                const isNotDip = Array.isArray(matchDip) === false || (
                    Array.isArray(matchDip) === true && matchDip.length === 0
                );

                if (isNotDip === true) {
                    const itemType = course.type;
                    const splitType = String(itemType).split(";").filter((value) => value !== '');

                    if (splitType.length > 1) {
                        return splitType[1] === cleanedType;
                    }
                }

                return false;
            });

            const newDipCourses = [];

            if (Array.isArray(dipCourses) && dipCourses.length > 0) {
                const reorderedDipCourses = _.orderBy(dipCourses, ['name', 'sorting']);

                [...reorderedDipCourses].forEach((course) => {
                    const newCourse = {...course};
                    const itemType = course.type;
                    const splitType = String(itemType).split(";").filter((value) => value !== '');

                    if (splitType.length > 1) {
                        newCourse.html = `&ensp; &ensp; &ensp; &ensp;${course.name}`;
                        newCourse.value = `${course.id}`;

                        if (
                            Object.prototype.hasOwnProperty.call(
                                dipartmentsValuesList,
                                splitType[1]
                            ) === false
                        ) {
                            dipartmentsValuesList[splitType[1]] = [];
                            dipartmentsValuesList[splitType[1]].push(newCourse.id);
                        } else {
                            dipartmentsValuesList[splitType[1]].push(newCourse.id);
                        }
                    }

                    newDipCourses.push({ ...newCourse });

                    if (splitType[0] === "IFTS") {
                        coursesIFTS.push({
                            ...newCourse,
                            html: `${course.name}`,
                            value: course.id,
                        });
                    }
                });
            }

            courses.push({...newDipartment});
            courses.push(...newDipCourses);
        });
    }

    // console.log('courses returned', courses);

    return courses;


    /***
     * 
     * OLD LOGIC
     * 
     */
    // apiCoursesResult.forEach((course) => {
    //     const newElement = { ...course };

    //     const find = String(course.type).match(patternDip);

    //     if (Array.isArray(find) === false || find.length === 0) {
    //         const itemType = course.type;
    //         const splitType = String(itemType).split(";").filter((value) => value !== '');

    //         if (splitType.length > 1) {
    //             newElement.html = `&ensp; &ensp; &ensp; &ensp;${course.name}`;
    //             newElement.value = `${course.id}`;

    //             if (
    //                 Object.prototype.hasOwnProperty.call(
    //                     dipartmentsValuesList,
    //                     splitType[1]
    //                 ) === false
    //             ) {
    //                 dipartmentsValuesList[splitType[1]] = [];
    //                 dipartmentsValuesList[splitType[1]].push(newElement.id);
    //             } else {
    //                 dipartmentsValuesList[splitType[1]].push(newElement.id);
    //             }
    //         }

    //         courses.push({ ...newElement });

    //         if (splitType[0] === "IFTS") {
    //             coursesIFTS.push({
    //                 ...newElement,
    //                 html: `${course.name}`,
    //                 value: course.id,
    //             });
    //         }
    //     }
    // });

    // apiCoursesResult.forEach((course) => {
    //     const newElement = { ...course };

    //     const find = String(course.type).match(patternDip);

    //     if (Array.isArray(find) && find.length > 0) {
    //         const cleanedType = course.type.replace("DIP_", "");

    //         newElement.html = `&ensp; &ensp;${course.name}`;

    //         const existsValuesList = Object.prototype.hasOwnProperty.call(
    //             dipartmentsValuesList,
    //             cleanedType
    //         );

    //         newElement.value = `${course.id}${
    //             existsValuesList === true
    //                 ? `,${dipartmentsValuesList[cleanedType].toString()}`
    //                 : ""
    //         }`;

    //         courses.push({ ...newElement });
    //         // typeDipartement.push(newElement);
    //     }
    // });

    // let uniqueTypeDipartement = [
    //     ...new Map(
    //         typeDipartement.map((item) => [item["code"], item])
    //     ).values(),
    // ];

    // uniqueTypeDipartement.forEach((element) => {
    //     if (element.type != "IFTS") {
    //         courses.push(element);
    //     } else {
    //         element.html = `${element.name}`;
    //         element.value = element.id;
    //         coursesIFTS.push(element);
    //     }
    // });

    // console.log("uniqueTypeDipartement", uniqueTypeDipartement);
    // console.log("courses", courses);

    // courses = courses.sort((a, b) => {
    //     return a.sorting - b.sorting;
    // });

    // courses.push({
    //     label: "IFTS ",
    //     options: coursesIFTS,
    //     sorting: 300,
    // });
}
